import { Frequency, IWalletBlockchain, WorkflowType, } from "@/types";

export const runInterval: Frequency[] = Object.values(Frequency);

export const workflowTypeOptions: { title: string, type: WorkflowType | "all" }[] = [
  {
    title: "All",
    type: "all"
  },
  {
    title: "Two-agent",
    type: "twoagents"

  },
  {
    title: "Groupchat",
    type: "groupchat"

  },
]

export const supportedBlockchain: { id: string, name: string, logo: string, isAvailable?: boolean,circleBlochainId?:IWalletBlockchain }[] = [
  {
    id: "eth-mainnet",
    name: "Ethereum",
    logo: "/assets/eth.svg",
    isAvailable: true,
    circleBlochainId:"ETH"

  },
  {
    name: "Avalanche",
    id: "avalanche-mainnet",
    logo: "/assets/avalanche.svg",
    circleBlochainId:"AVAX"

  },
  {
    name: "Polygon",
    id: "matic-mainnet",
    logo: "/assets/polygon.svg",
    circleBlochainId:"MATIC"
  },
]



export const colors = [
  "#92A1C6", "#146A7C", "#F0AB3D", "#C271B4", "#C20D90",
  "#69D2E7", "#A7DBD8", "#E0E4CC", "#F38630", "#FA6900",
  "#ECD078", "#D95B43", "#C02942", "#53777A", "#542437",
  "#53777A", "#556270", "#4ECDC4", "#C7F464", "#FF6B6B",
  "#C44D58", "#FFFAAD", "#C8C8A9", "#83AF9B", "#3B3738",
  "#CC0C39", "#E6781E", "#C8CAE4", "#8A9B0F", "#00A0B0",
  "#FFADAD", "#FFD6A5", "#FDFFB6", "#CAFFBF", "#9BF6FF",
  "#A0C4FF", "#BDB2FF", "#FFC6FF", "#FFFFFC", "#FFDEDE",
  "#E1E5EA", "#B8B8D1", "#A3BE8C", "#B48EAD", "#A3A3A3",
  "#5E81AC", "#BF616A", "#D08770", "#EBCB8B", "#A3BE8C",
  "#8FBCBB", "#88C0D0", "#81A1C1", "#5E81AC", "#EBCB8B",
  "#D08770", "#8C7AA9", "#6A4C93", "#B98389", "#D6A2E8",
  "#6D6875", "#B5838D", "#E5989B", "#FFB4A2", "#FFCDB2",
  "#D8E2DC", "#FFE5D9", "#FFCAD4", "#F4ACB7", "#9D8189",
  "#A8DADC", "#457B9D", "#1D3557", "#F4A261", "#2A9D8F",
  "#E76F51", "#264653", "#2C7DA0", "#2B2D42", "#8D99AE",
  "#EDF2F4", "#EF233C", "#D90429", "#8D99AE", "#EDF2F4",
  "#E9C46A", "#F4A261", "#E76F51", "#3A86FF", "#8338EC"
];