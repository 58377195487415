import { db } from "@/lib/db";
import { Balance } from "@circle-fin/developer-controlled-wallets/dist/types/clients/developer-controlled-wallets";
import { BalanceItem } from "@covalenthq/client-sdk";
import { Agent, Session, Skill, Task, User, Wallet, Workflow, GroupChatConfig, GroupChatManager, Message, Secret } from "@prisma/client"


export type PostGroupChatManager = Pick<GroupChatManager, "config" | "type">
export type PostAgent = Pick<Agent, "config" | "type" | "isTeachable" | "skillsIds">
export type PostGroupchatConfig = Pick<GroupChatConfig, "max_round" | "admin_name" | "agentIds">
export interface BaseEntity {
  id?: string
  created_at?: Date;
  updated_at?: Date;
}
export enum Blockchain {
  ETH = "ETH",
  AVAX = "AVAX",
  MATIC = "MATIC",

}
export type SiteConfig = {
  name: string;
  description: string;
  url: string;
  ogImage: string;
  links: {
    twitter: string;
    github?: string;
  };
};

export type ViewType = 'skills' | 'agents' | 'workflows';
export type PlanType = "basic" | "enhanced" | "premium" | "exclusive" | "free"
export type IWalletBlockchain = "ETH" | "AVAX" | "MATIC"

export interface ITransfer extends BaseEntity {
  tx_id: string;
  state?: string;
  user_id: string;
  blockchain?: string;
  token_address?: string
  token_symbol?: string
  tokenId?: string;
  walletId?: string;
  sourceAddress?: string;
  destinationAddress?: string;
  transactionType?: string;
  amountInUSD?: string;
  networkFeeInUSD?: string;
  txHash?: string;
  amounts?: string[];
  blockHash?: string;
  networkFee?: string;
  firstConfirmDate?: string;
  feeLevel?: string;
  createDate?: string;
  updateDate?: string;
  estimatedFee?: { [key: string]: string };
  operation?: string
}

// interface IPlan {
//   plan_name: "basic" | "enhanced" | "premium";
//   plan_duration?: "monthly" | "yearly";
//   start_date?: Date | null;
//   end_date?: Date | null;
//   is_active: boolean;
// }

export interface IMetadataFile {
  name: string;
  path: string;
  extension: string;
  content: string;
  type: string;
}
export interface IExtendedIBalances extends BalanceItem {
  blockchain: string
}
export interface IToken {
  id: string;
  blockchain: IWalletBlockchain;
  name: string;
  symbol: string;
  decimals: number;
  isNative: boolean;
  updateDate: string;
  createDate: string;
  tokenAddress?: string;
  standard?: string;
}





export interface BalanceWithPrice extends Balance {
  tokenPrice: number
  amountInUSD: string
  walletId: string
}
export interface WalletBalance {
  walletId: string;
  balances: BalanceWithPrice[]
  address: string;
  totalBalance: number

}
export interface IChatMessage {
  text: string;
  sender: "user" | "bot";
  metadata?: any;
  msg_id: string;
}
export interface ConfigState {
  tasks: Task[];
  setTasks: (tasks: Task[]) => void;
  assistants: Agent[];
  setAssistants: (assistants: Agent[]) => void;
  skills: Skill[];
  setSkills: (skills: Skill[]) => void;
  transferDetails: ITransferDetails
  setTransferDetails: (transferDetails: ITransferDetails) => void
  feeLevel: IGasFee | null
  setFeeLevel: (feeLevel: IGasFee) => void
  isStepCompleted: boolean
  setIsStepCompleted: (isStepCompleted: boolean) => void
  selectedWallet: Wallet | null
  setSelectedWallet: (selectedWallet: Wallet | null) => void
  tokenTransferData: IUserTransferRequestModel | null
  setTokenTransferData: (tokenTransferData: IUserTransferRequestModel | null) => void
  token: BalanceWithPrice | null
  setToken: (token: BalanceWithPrice | null) => void
  workflowConfigs: Workflow[];
  setWorkflowConfigs: (flowConfig: Workflow[]) => void;

  messages: IChatMessage[] | null;
  setMessages: (messages: IChatMessage[]) => void;
  session: Session | null;
  setSession: (session: Session | null) => void;
  sessions: Session[];
  setSessions: (sessions: Session[]) => void;
  version: string | null;
  setVersion: (version: string) => void;
  connectionId: string;
  setConnectionId: (connectionId: string) => void;
}
export interface IChatSession extends BaseEntity {
  user_id: string
  workflow_id: string
  type: "gemach_ai" | "data"
  title: string
  mode: WorkflowType
}




export interface IParsedUserWallet extends Omit<Wallet, 'blockchain'> {
  blockchains: string[];
}
export interface IUser extends BaseEntity {
  username?: string
  user_address: string | undefined;
  user_dir?: string
  gemach_holding?: number;
  wallet_set_id?: string;
  agent_wallets: Wallet[];
  plan: ISubscription;
  general_workspace_path?: string
  last_active: string;
}

export interface IAppContext {
  user: User | undefined
  setUSer: (user: any) => void
  // directly pull user state from the server
  pullRemoteState: () => void
}


export interface IStatus {
  status: boolean;
  message: string;
}
export interface ILLMConfig {
  config_list: any[];
  temperature: number;
  cache_seed?: number | null;
  timeout: number;
  functions?: { [key: string]: any }[];
}

export enum Frequency {
  HOURLY = "HOURLY",
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  MINUTELY = "MINUTELY",
  SECONDLY = "SECONDLY",
}

export interface IPlanFeatures {
  name: PlanType
  agents: number,
  teachabilities: number,
  wallets: number,
  runIntervals: Frequency[],
  workflows: number
  workflowType: WorkflowType[]
  maxRound: number
  summaryMethod: SummaryMethodType[]
  maxAutoReply: number
  tasks: number
}
export interface ISubscription {
  plan_name: PlanType
  plan_duration?: "monthly" | "yearly";
  start_date?: string | null;
  end_date?: string | null;
  is_active: boolean;

}
export interface IAgentConfig {
  name: string;
  description: string;
  llm_config?: ILLMConfig | boolean | null;
  human_input_mode: "NEVER" | "ALWAYS";
  max_consecutive_auto_reply: number;
  system_message?: string | null;
  is_termination_msg?: boolean | string | ((...args: any[]) => any) | null;
  code_execution_config?: boolean | string | { [key: string]: any } | null;
}

export interface IAgentFlowSpec extends BaseEntity {
  type: "assistant" | "userproxy";
  config: IAgentConfig;
  user_id: string | null;
  skills: Skill[]
  skills_id: string[]
  is_teachable?: boolean;
  assigned_wallets: Wallet[]
}
export interface IMessage {
  user_id: string;
  role: string;
  content: string;
  session_id: string;
  root_msg_id?: string | null;
  msg_id?: string | null;
  personalize?: boolean;
  ra?: string | null;
  code?: string | null;
  metadata?: any; // `Any` type in TypeScript is denoted as `any`
  receiver?: string | null;
  sender?: string | null;
}


export interface IGroupChatConfig {
  agents: IAgentFlowSpec[];
  admin_name: string;
  messages: { [key: string]: any }[];
  max_round?: number;
  speaker_selection_method?: string;
  allow_repeat_speaker?: boolean | IAgentConfig[];
}

export interface IGroupChatFlowSpec {
  type: "groupchat";
  config: IAgentConfig;
  groupchat_config: IGroupChatConfig
  user_id?: string | null;
  description?: string | null;
  skills?: Skill[] | null;
}
export interface IModelConfig {
  model: string;
  api_key?: string;
  api_version?: string;
  base_url?: string;
  api_type?: string;
  user_id?: string;
  timestamp?: string;
  description?: string;
  id?: string;
}
export type PostWorkflow = Pick<Workflow, "name" | "type" | "description" | "summary_method" | "groupchatReceiverId" | "receiverId" | "senderId" | "wallets">
export type WorkflowType = "twoagents" | "groupchat"

export type SummaryMethodType = "llm" | "last"


interface IGasFee {
  gasLimit: string;
  gasPrice: string;
  maxFee: string;
  priorityFee: string;
  baseFee: string;
}


export interface IGasEstimate {
  high: IGasFee;
  low: IGasFee;
  medium: IGasFee;

}

interface IChatWebGroupchatRequestModel {
  message: IMessage; // Assuming `Message` is a previously defined or external type
  flow_config?: Workflow | null;
}
interface EstimatedTransactionFee {
  gasLimit?: string;
  gasPrice?: string;
  maxFee?: string;
  priorityFee?: string;
  baseFee?: string;
}
export interface IUserTransferRequestModel {
  walletId: string; // Circle wallet id
  destinationAddress: string;
  amount: string[];
  tokenId: string;
  feeLevel: "MEDIUM" | "LOW" | "HIGH";
  gasLimit?: string;
  maxFee?: string;
  priorityFee?: string;
  baseFee?: string;
}

export interface ITransferDetails {
  amount: number
  gasfee: number
}

export interface IUserCreateWalletRequestModel {
  name: string;
  blockchains: IWalletBlockchain[]
}


export interface IDBWebRequestModel {
  user_id: string;
  msg_id?: string | null;
  session?: Session | null;
  message?: Pick<Message, "role" | "content" | "msgId" | "userId" | "rootMsgId" | "sessionId">
  connection_id?: string
  skill?: Skill | null;
  tags?: string[] | null;
  agent?: IAgentFlowSpec | null;
  workflow?: Workflow | null;
  transfer?: IUserTransferRequestModel | null;
  wallet?: IUserCreateWalletRequestModel | null;
  wallet_ids?: string[] | null;
  task?: Task | null;
}