'use client';
import React, { useEffect } from 'react'
// import { nav } from '@/config/nav'
import { usePathname } from 'next/navigation'
import { ConnectButton, useActiveAccount, } from "thirdweb/react";
// import { useActiveAccount } from 'thirdweb/react';
import Avatar from "boring-avatars";
import { defineChain } from 'thirdweb';
import { ChevronDown } from 'lucide-react';
import { colors } from '@/config';
import { SupportedTokens, } from 'thirdweb/react';
import { MainNavItem } from '@/types/componentsTypes';
import { useRouter } from 'next/navigation'
import { client } from "@/lib/client";
import { darkTheme } from "thirdweb/react";
import { createWallet } from "thirdweb/wallets";

import {
    generatePayload,
    isLoggedIn,
    // isLoggedIn,
    login,
    logout,
} from "@/actions/auth";
interface MainNavProps {
    items?: MainNavItem[]
    children?: React.ReactNode
}

interface IConnectButtonProps {
    btnTitle: string
    hideModal?: boolean
    children?: React.ReactNode

}

const ConnectWalletButton = ({ btnTitle, hideModal = false, children }: IConnectButtonProps) => {
    const [avatarColors, setAvatarColor] = React.useState<string[]>()
    const account = useActiveAccount();
    // DATA reimagines blockchain interaction by combining AI, collective intelligence, and autonomous operation, transforming how people and organizations participate in Defi.
    const appMetadata = {
        name: "Gemach",
        url: "https://gemach.io",
        description: `DATA and GEMACH AI: Transforming DeFi Interaction
Experience autonomous, AI-driven blockchain participation that leverages collective intelligence for revolutionary DeFi engagement.`,
        logoUrl: "https://data.dev.gemach.io/assets/logo.svg",
    }
    const tokens: SupportedTokens = {
        1: [
            {
                name: "Gemach",
                symbol: "GMACH",
                address: "0xd96e84ddbc7cbe1d73c55b6fe8c64f3a6550deea",
            }
        ]

    }
    const wallets = [
        createWallet("io.metamask"),
        createWallet("com.trustwallet.app"),
        createWallet("com.coinbase.wallet"),
    ]
    const supportedChain = defineChain(1)
    useEffect(() => {
        const localColors = localStorage.getItem("avatar_colors")
        if (localColors) {
            setAvatarColor(JSON.parse(localColors))
        } else {
            const generatedColors = colors.sort(() => 0.5 - Math.random()).slice(0, 5)
            setAvatarColor(generatedColors)
            localStorage.setItem("avatar_colors", JSON.stringify(generatedColors))
        }
    }, [])
    return (
        <ConnectButton client={client}
            appMetadata={
                appMetadata
            }

            supportedTokens={tokens}
            auth={{
                isLoggedIn: async (address) => {
                    // make request to get the user's data
                    return await isLoggedIn();
                },
                doLogin: async (params) => {
                    await login(params);
                },
                getLoginPayload: async ({ address }) =>
                    generatePayload({ address }),


                doLogout: async () => {
                    await logout();
                },

            }}

            theme={
                darkTheme({
                    colors: {

                        accentText: "#61B8FF",
                        accentButtonBg: "#61B8FF",
                        modalBg: "#060A17",
                        secondaryText: "#A1A5B3",
                        primaryText: "#D5D9E1",
                        accentButtonText: "#060A17",
                        connectedButtonBg: "#162139",
                        // walletSelectorButtonHoverBg:
                        //     "#1F273D",
                        primaryButtonBg: "#61B8FF",
                        borderColor: "#262830",
                        secondaryButtonHoverBg: "#1F273D",
                        skeletonBg: "#1F273D",
                        connectedButtonBgHover: "#1F273D",

                    },
                    fontFamily: "Inter",
                })
            }
            autoConnect={true}
            showAllWallets={false}
            connectButton={{
                style: {
                    borderRadius: "6px",
                    height: `${account?.address ? "45px" : "41px"}`,
                    fontSize: "14px",
                    fontWeight: "600",
                    backgroundColor: "#61B8FF",
                },

            }
            }

            detailsButton={
                {

                    render: () => {

                        return (
                            <div onClick={(e) => {
                                if (hideModal) {

                                    e.stopPropagation()
                                }
                            }} >
                                {children}
                            </div>
                        )
                    }
                }
            }
            recommendedWallets={
                wallets
            }
            chains={[supportedChain]}

        />


    )
}

export default ConnectWalletButton