'use client';
import React, { useEffect } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { colors } from '@/config';
import ConnectButton from './connect-button';
import { ChevronDown, Menu, Plus } from 'lucide-react';
import Avatar from "boring-avatars";
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import {
    Sheet,
    SheetClose,
    SheetContent,
    SheetDescription,
    SheetFooter,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
} from "@/components/ui/sheet"
import { useActiveAccount, } from "thirdweb/react";
import { MainNavItem } from '@/types/componentsTypes';
interface MainNavProps {
    items?: MainNavItem[]
    children?: React.ReactNode
}

const Navbar = ({ items }: MainNavProps) => {
    const account = useActiveAccount();

    const publicRoutes = ["/"]
    const [avatarColors, setAvatarColor] = React.useState<string[]>()
    const pathname = usePathname();
    useEffect(() => {
        const localColors = localStorage.getItem("avatar_colors")
        if (localColors) {
            setAvatarColor(JSON.parse(localColors))
        } else {
            const generatedColors = colors.sort(() => 0.5 - Math.random()).slice(0, 5)
            setAvatarColor(generatedColors)
            localStorage.setItem("avatar_colors", JSON.stringify(generatedColors))
        }
    }, [])
    return (
        <nav className=' px-3 md:px-10   py-0 border-b border-[#162139]  fixed top-0 z-50 flex h-16 w-full shrink-0 items-center justify-between  bg-background'>
            <Link className='flex gap-2 items-center justify-center' href='/'>
                <Image src='/assets/logo.svg' alt='logo' width={25} height={29} />
                <h1 className='font-bold leading-5 text-accent'>GEMACH AI</h1>
            </Link>
            <ul className={`gap-10 text-sm md:flex xs:hidden  items-center  flex-1 lg:ml-40 md:ml-20 h-full ${publicRoutes.includes(pathname) ? " justify-center" : "justify-start font-semibold"}`}>
                {items?.map((item, index) => (
                    <div key={index} className="flex flex-col h-full justify-between font-medium text-base">
                        <div className="flex justify-center items-center flex-grow">
                            <Link className={`${pathname.startsWith(item.href) && "text-accent"}`} href={item.href} target={item.href.includes("docs") ? "_blank" : "_self"}>{item.title}</Link>
                        </div>
                        <div className="flex justify-center items-center">
                            <span className={`${pathname.startsWith(item.href) && "w-8/12 h-[2px] bg-accent rounded "}`} />
                        </div>
                    </div>

                ))}

            </ul>

            <div className='flex gap-4 items-center'>
                <ConnectButton btnTitle='Connect Wallet'  >
                    <button className='inline-flex items-center justify-center gap-2 bg-[#162139] rounded-lg px-3 py-2 text-sm text-muted-foreground font-medium'>
                        <Avatar variant='pixel' size={24} key={1} colors={avatarColors} />
                        <div className=' gap-2 items-center justify-center xs:hidden sm:flex '>
                            {`${account?.address?.slice(0, 6)} ...  ${account?.address?.slice(-4)}`}
                            <ChevronDown size={24} />
                        </div>
                    </button>
                </ConnectButton>
                {
                    pathname === "/" && <Sheet >
                        <SheetTrigger asChild>
                            <Menu className=' h-6 w-6 silver-text md:hidden block' />

                        </SheetTrigger>
                        <SheetContent side='top' className=' h-full '>
                            <SheetHeader>
                                <div className='flex justify-between items-center'>
                                    <Link className='flex gap-2 items-center justify-start' href='/'>
                                        <Image src='/assets/logo.svg' alt='logo' width={25} height={29} />
                                        <h1 className='font-bold leading-5 text-accent'>GEMACH AI</h1>
                                    </Link>
                                    <SheetClose asChild>
                                        <Plus className='h-6 w-6 rotate-45' />
                                    </SheetClose>
                                </div>
                            </SheetHeader>
                            <ul className={`text-sm flex h-full flex-col  py-4 justify-start`}>
                                {items?.map((item, index) => (
                                    <li key={index} className="flex flex-col   font-medium text-base px-4 gap-3 my-2">
                                        <Link className={`${pathname.includes(item.href) && "text-accent"}`} href={item.href}>{item.title}</Link>
                                        <div className=' w-full' style={{ border: "1px solid rgba(24, 34, 57, 1)" }} />

                                    </li>

                                ))}

                            </ul>

                        </SheetContent>
                    </Sheet>
                }
            </div>

        </nav>
    )
}

export default Navbar